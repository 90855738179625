<template>
  <div class="menber-dialog">
    <el-dialog
      title="发布新供应"
      :visible.sync="visible"
      :before-close="handleClose"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="1338px"
      top="10px"
    >
      <table border="1">
        <tr>
          <td>联系人</td>
          <td class="dis">
            <el-input :disabled="true" v-model="form.lxr"></el-input>
          </td>
          <td>联系人电话</td>
          <td class="dis">
            <el-input :disabled="true" v-model="form.lxfs"></el-input>
          </td>
        </tr>
        <tr>
          <td>产权类型 <span>*</span></td>
          <td>
            <el-select v-model="form.cqlxbh">
              <el-option
                v-for="(item, index) in types"
                :key="index"
                :value="item.bh"
                :label="item.lx"
              ></el-option>
            </el-select>
          </td>
          <td>所在区域 <span>*</span></td>
          <td>
            <el-cascader
              ref="xmdz"
              v-model="form.xzqbh"
              :options="proviceArr"
              :props="prop"
              style="width: 100%"
              @change="handleChange"
            ></el-cascader>
          </td>
        </tr>
        <tr>
          <td>流转类型 <span>*</span></td>
          <td>
            <el-radio-group v-model="form.lzlx">
              <el-radio :label="3" v-if="userType == '3' && form.cqlxbh == '01'"
                >出让</el-radio
              >
              <el-radio :label="0">出租</el-radio>
              <el-radio :label="1">入股</el-radio>
              <el-radio :label="2" v-if="userType != '3' && form.cqlxbh == '01'"
                >转让</el-radio
              >
            </el-radio-group>
          </td>
          <td>交易方式 <span>*</span></td>

          <td v-if="form.cqlxbh == '01' && userType == '3'">
            <!-- <el-select v-model="form.jyfs">
              <el-option value="在线竞价交易">在线竞价交易</el-option>
              <el-option value="在线磋商">在线磋商</el-option>
            </el-select> -->
            <el-input :disabled="true" v-model="form.jyfs"></el-input>
          </td>
          <td v-else>
            <el-input :disabled="true" v-model="form.jyfs"></el-input>
          </td>
        </tr>
        <tr>
          <td>转出面积</td>
          <td>
            <el-input v-model="form.zcmj" style="width: 50%"  @change="zjChange"></el-input>
            <el-select v-model="form.mjdw" style="width: 90px">
              <el-option :value="'0'" label="平方米"></el-option>
              <el-option :value="'1'" label="亩"></el-option>
            </el-select>
          </td>
          <td>流转年限 <span>*</span></td>
          <td>
            <el-input v-model="form.lznx" style="width: 40%"  @change="zjChange"
              ><template slot="append">年</template></el-input
            >
          </td>
        </tr>
        <tr>
          <td>挂牌总价 <span>*</span></td>
          <td>
            <el-input
              v-model.number="form.gpjg"
              @change="zjChange"
              style="width: 50%"
              type="number"
              min="1"
              ><template slot="append">元</template></el-input
            >
            <p v-if="form.lzlx==1">
              (出资额占比<input
              type="number"
              v-model.number="form.proportionInvestment"
              style="width: 40px; border-bottom: 1px solid #333333"
            />%)
            </p>

            <!-- oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" -->
          </td>
          <td>挂牌单价</td>
          <td>
            <el-input
              v-model.number="form.gpdj"
              style="width: 50%"
              type="number"
              :readonly="true"
            ></el-input
            ><el-select v-model="form.mjdw" style="width: 120px">
              <el-option :value="'0'" label="元/平方米/年"></el-option>
              <el-option :value="'1'" label="元/亩/年"></el-option>
            </el-select>
          </td>
        </tr>
        <tr v-if="!(userType == '3')">
          <td>是否属再流转 <span>* </span></td>
          <td>
            <!-- userType == 2 ? "1" :" ", // 根据用户来判断显示 "是" "否" -->
            <el-radio-group v-model="form.sfsyzlz">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </td>
          <td>再流转同意证明</td>
          <td :colspan="form.sfsyzlz == '1' ? 3 : 1">
            <!-- 图片上传 -->
            <el-upload
              v-if="form.sfsyzlz == '1'"
              class="upload-demo"
              action=""
              :auto-upload="false"
              name="files"
              :accept="'.pdf, .PDF,.jpg, .JPG, .png, .PNG'"
              :on-preview="downloadZlzzm"
              :before-upload="beforeUpload"
              :on-change="uploadZlzzm"
              :on-remove="removeZlzzm"
              :file-list="fileList3"
              multiple
              :limit="6"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
                v-if="fileList3 && fileList3.length == 0"
              >
                请上传PDF或图片格式
              </div>
            </el-upload>
          </td>
        </tr>
        <tr v-if="userType == '3' && form.cqlxbh == '01'">
          <td>东至</td>
          <td><el-input v-model="form.dz"></el-input></td>
          <td>西至</td>
          <td><el-input v-model="form.xz"></el-input></td>
        </tr>
        <tr v-if="userType == '3' && form.cqlxbh == '01'">
          <td>南至</td>
          <td><el-input v-model="form.nz"></el-input></td>
          <td>北至</td>
          <td><el-input v-model="form.bz"></el-input></td>
        </tr>
        <tr class="long">
          <td>产权证书 <span>*</span><br />(或其他佐证材料)</td>
          <td colspan="3">
            <el-upload
              class="upload-demo"
              action=""
              :auto-upload="false"
              name="files"
              :accept="'.pdf, .PDF,.jpg, .JPG, .png, .PNG'"
              :on-preview="downloadCqzs"
              :on-progress="progress"
              :before-upload="beforeUpload"
              :on-change="uploadCqzs"
              :on-remove="removeCqzs"
              :on-success="uploadFileSuccess"
              :file-list="fileList"
              list-type="picture"
              multiple
              :limit="6"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
                v-if="fileList && fileList.length == 0"
              >
                请上传PDF格式或图片
              </div>
              <div class="页面设置"></div>
            </el-upload>
          </td>
        </tr>
        <tr class="long">
          <td>上传现场图片</td>
          <td colspan="3">
            <el-upload
              class="upload-demo"
              action=""
              :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG, WebP'"
              :on-preview="downloadXctp"
              :auto-upload="false"
              :on-change="uploadXctp"
              :on-remove="removeXctp"
              :file-list="fileList2"
              list-type="picture-card"
              multiple
              :limit="6"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
                v-if="fileList2 && fileList2.length == 0"
              >
                单张图片最大6M，最多上传6张，支持：JPG/JPEG/GIF/PNG格式。
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </td>
        </tr>
        <tr class="long" v-if="userType == '3' && form.cqlxbh == '01'">
          <td>地块规划设计条件审批结果 <span>*</span></td>
          <td colspan="3">
            <el-upload
              class="upload-demo"
              action=""
              :auto-upload="false"
              name="files"
              :accept="'.pdf, .PDF,.jpg, .JPG, .png, .PNG'"
              :on-preview="downloadSpjg"
              :before-upload="beforeUpload"
              :on-change="uploadSpjg"
              :on-remove="removeSpjg"
              :file-list="fileList4"
              multiple
              :limit="6"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
                v-if="fileList4 && fileList4.length == 0"
              >
                请上传PDF/JPG/JPEG/GIF/PNG格式
              </div>
            </el-upload>
          </td>
        </tr>
        <tr class="long" v-if="userType == '3' && form.cqlxbh == '01'">
          <td>地块评估价值结果 <span>*</span></td>
          <td colspan="3">
            <el-upload
              class="upload-demo"
              action=""
              :on-preview="downloadPgjg"
              :auto-upload="false"
              name="files"
              :accept="'.pdf, .PDF,.jpg, .JPG, .png, .PNG'"
              :before-upload="beforeUpload"
              :on-change="uploadPgjg"
              :on-remove="removePgjg"
              :file-list="fileList5"
              multiple
              :limit="6"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
                v-if="fileList5 && fileList5.length == 0"
              >
                请上传PDF/JPG/JPEG/GIF/PNG格式
              </div>
            </el-upload>
          </td>
        </tr>
        <tr class="long" v-if="userType == '3' && form.cqlxbh == '01'">
          <td>付款方式说明</td>
          <td colspan="3">
            <el-input
              v-model="form.fkfs"
              style="width: 98%"
              type="textarea"
              maxlength="200"
              show-word-limit
              placeholder="*约定具体付款方式，如按年度支付，每年12月31日前缴清下一年承包费"
            ></el-input>
          </td>
        </tr>
        <tr class="long">
          <td>其它说明</td>
          <td colspan="3">
            <el-input
              v-model="form.qtsm"
              style="width: 98%"
              type="textarea"
              maxlength="200"
              show-word-limit
            ></el-input>
          </td>
        </tr>

        <!-- 在线竞价交易 -->
        <template v-if="form.jyfs == '在线竞价交易'">
          <tr>
            <td>保证金金额 <span>*</span></td>
            <td>
              <el-input
                v-model.number="form.jybzj"
                style="width: 200px"
                type="number"
                :min="1"
                ><template slot="append">元</template></el-input
              >
            </td>
            <td>加价最小金额 <span>*</span></td>
            <td>
              <el-input
                v-model.number="form.jjfd"
                style="width: 200px"
                type="number"
                :min="1"
                ><template slot="append">元</template></el-input
              >
            </td>
          </tr>
          <tr>
            <!-- 竞拍的截止时间 -->
            <td>竞拍截至时间 <span>*</span></td>
            <td>
              <el-date-picker
                v-model="form.jjjzsj"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                placeholder="选择日期时间"
                :default-time="'12:00:00'"
              >
              </el-date-picker>
            </td>
            <td>竞拍延时 <span>*</span></td>
            <td style="display: flex; height: 36px">
              <el-input
                v-model="form.jpys"
                style="width: 120px"
                type="number"
                min="1"
                ><template slot="append">分钟</template></el-input
              ><b style="display: inline-block; width: 70%; font-weight: normal"
                >（出价时，若距离截止时间小于设置时间，将自动延长截止时间）</b
              >
            </td>
          </tr>
        </template>

        <!-- 补充交易详细约定 -->
        <template v-if="!(form.cqlxbh == '01' && form.lzlx == 2)">
          <tr v-if="!(userType == '3' && form.cqlxbh == '01')">
            <td colspan="4"><h4>补充交易详细约定(选填)</h4></td>
          </tr>
          <tr v-if="!(userType == '3' && form.cqlxbh == '01')">
            <td>流转后用途</td>
            <td colspan="3">
              <input
                type="text"
                v-model="form.lzhdyt"
                placeholder="请填写用途，如：蔬菜种植"
                style="width: 100%"
              />
            </td>
          </tr>

          <!-- 租金或分红 -->
          <template v-if="!form.lzlx == 1">
            <tr v-if="!(userType == '3' && form.cqlxbh == '01')">
              <td>租金或收益调整周期</td>
              <td>
                每
                <input
                  type="text"
                  v-model="form.syzq"
                  checked
                  style="width: 20px; height: 15px; border: 1px solid #333333"
                />
                年调整一次租金
              </td>
              <td>具体调整方式:</td>
              <td>
                <input
                  type="text"
                  v-model="form.tzfs"
                  style="width: 100%; border-bottom: 1px solid #333333"
                />
              </td>
            </tr>
            <tr v-if="!(userType == '3' && form.cqlxbh == '01')">
              <td>租金支付方式 <span v-show="form.zffs != 0">*</span></td>
              <td>
                <el-select v-model="form.zffs">
                  <el-option :value="0" label="请选择">请选择</el-option>
                  <el-option :value="1" label="一次性支付"
                    >一次性支付</el-option
                  >
                  <el-option :value="2" label="分期支付">分期支付</el-option>
                </el-select>
              </td>
              <td colspan="2">
                <p v-if="form.zffs == '2'">
                  乙方须于每年
                  <!-- <input
                type="text"
                v-model="form.txrq"
                placeholder="请填写日期"
                style="
                  width: 100px;
                  border-bottom: 1px solid #333333;
                  text-align: center;
                "/> -->
                  <!-- <el-date-picker
                v-model="form.txrq"
                type="datetime"
                placeholder="选择日期时间"
                :default-time="'12:00:00'"
                style="width: 200px"
              >
              </el-date-picker> -->

                  <el-date-picker
                    v-model="form.txrq"
                    type="datetime"
                    placeholder="选择日期"
                    style="width: 200px"
                    value-format="MM 月 dd 日"
                    format="MM 月 dd 日"
                  >
                  </el-date-picker>
                  前支付(
                  <el-radio-group v-model="form.zfsj">
                    <el-radio :label="'0'">当</el-radio>
                    <el-radio :label="'1'">后</el-radio> </el-radio-group
                  >一)年租金
                </p>
                <p v-if="form.zffs == '1'">
                  乙方须于
                  <!-- <input
                type="text"
                v-model="form.txrq"
                placeholder="请填写日期"
                style="
                  width: 100px;
                  border-bottom: 1px solid #333333;
                  text-align: center;
                "/> -->
                  <el-date-picker
                    v-model="form.txrq"
                    type="datetime"
                    placeholder="选择日期"
                    style="width: 200px"
                    value-format="yyyy 年 MM 月 dd 日"
                    format="yyyy 年 MM 月 dd 日"
                  >
                  </el-date-picker>
                  <!-- <el-date-picker
                v-model="form.txrq"
                type="datetime"
                placeholder="选择日期时间"
                :default-time="'12:00:00'"
                style="width: 200px"
              >
              </el-date-picker> -->
                  前支付租金。
                </p>
              </td>
            </tr>
          </template>

          <template v-if="form.lzlx == 1">
            <tr v-if="!(userType == '3' && form.cqlxbh == '01')">
              <td>股份分红标准 <span v-show="form.gffhbz != 0">*</span></td>
              <td>
                <el-select v-model="form.gffhbz">
                  <el-option :value="0" label="请选择">请选择</el-option>
                  <el-option :value="1" label="按股分红">按股分红</el-option>
                  <el-option :value="2" label="保底收益+按股分红"
                    >保底收益+按股分红</el-option
                  >
                </el-select>
              </td>
              <td colspan="2" style="text-align: left">
                <p v-if="form.gffhbz == 2">
                  保底收益每亩每年
                  <input
                    type="text"
                    v-model="form.bdsyqs"
                    checked
                    style="
                      width: 20px;
                      height: 15px;
                      border-bottom: 1px solid #333333;
                    "
                  />元，每<input
                    type="text"
                    v-model="form.bdsyns"
                    checked
                    style="
                      width: 20px;
                      height: 15px;
                      border-bottom: 1px solid #333333;
                    "
                  />年调整一次保底收益。<br /><br />

                  具体调整方式：<input
                    type="text"
                    v-model="form.bdsytzfs"
                    checked
                    style="
                      width: 80%;
                      height: 15px;
                      border-bottom: 1px solid #333333;
                    "
                  />
                </p>
                <p v-if="!form.gffhbz == 0">
                  根据
                  <el-radio-group v-model="form.fhgjlx">
                    <el-radio :label="'0'">出资额</el-radio>
                    <!-- <el-radio :label="'1'">股份数</el-radio>
                    <el-radio :label="'2'">其他：</el-radio> -->
                  </el-radio-group>
                  <input
                    type="text"
                    v-model="form.fhgjxq"
                    checked
                    style="width: 60px; border-bottom: 1px solid #333333"
                    v-if="form.fhgjlx == 2"
                  />

                  分配盈余或者利润
                </p>
              </td>
            </tr>
            <tr v-if="!(userType == '3' && form.cqlxbh == '01')">
              <td>股份分红支付</td>
              <td colspan="3" style="text-align: left">
                <p v-if="form.gffhbz == 2">
                  乙方须于每年<el-date-picker
                    v-model="form.bdsyrq"
                    type="datetime"
                    placeholder="选择日期"
                    style="width: 200px"
                    value-format="MM 月 dd 日"
                    format="MM 月 dd 日"
                  >
                  </el-date-picker
                  >前支付(
                  <el-radio-group v-model="form.bdsysj">
                    <el-radio :label="'0'">当</el-radio>
                    <el-radio :label="'1'">后一</el-radio> </el-radio-group
                  >)年保底收益。
                </p>
                <p v-if="form.gffhbz != 0">
                  乙方须于每年<el-date-picker
                    v-model="form.fhrq"
                    type="datetime"
                    placeholder="选择日期"
                    style="width: 200px"
                    value-format="MM 月 dd 日"
                    format="MM 月 dd 日"
                  >
                  </el-date-picker>
                  前分配(
                  <el-radio-group v-model="form.fhsj">
                    <el-radio :label="'0'">前一</el-radio>
                    <el-radio :label="'1'">当</el-radio> </el-radio-group
                  >)年盈余或者利润。
                </p>
              </td>
            </tr>
          </template>

          <!-- 农户名单 -->
          <tr v-if="!(userType == '3' && form.cqlxbh == '01')">
            <td colspan="4">
              <p style="float: left">
                <el-checkbox v-model="checked1"
                  >我是代表多名农户统一流转土地
                </el-checkbox>
              </p>
            </td>
          </tr>
          <tr v-if="checked1 && !(userType == '3' && form.cqlxbh == '01')">
            <td colspan="4" style="margin: aout">
              <table border="1" class="farmers-table">
                <thead>
                  <tr>
                    <td colspan="4"><h2>农户名单</h2></td>
                  </tr>
                  <tr>
                    <th>农户姓名</th>
                    <th>面积（亩）</th>
                    <th>身份证号</th>
                    <th>联系方式</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(farmer, index) in form.farmersList" :key="index">
                    <td
                      :class="{ 'error-cell': errorStatus[index].nameError }"
                      style="width: 130px !important"
                    >
                      <input
                        type="text"
                        v-model="farmer.farmerName"
                        @blur="validateInput(farmer.farmerName, 'name', index)"
                      />
                    </td>
                    <td
                      :class="{ 'error-cell': errorStatus[index].areaError }"
                      style="width: 130px !important"
                    >
                      <input
                        type="text"
                        v-model="farmer.area"
                        @blur="validateInput(farmer.area, 'area', index)"
                      />
                    </td>
                    <td
                      :class="{
                        'error-cell': errorStatus[index].identityError,
                      }"
                      style="width: 380px !important"
                    >
                      <input
                        type="text"
                        v-model="farmer.identityId"
                        @blur="validateInput(farmer.identityId, 'id', index)"
                      />
                    </td>
                    <td
                      :class="{ 'error-cell': errorStatus[index].phoneError }"
                      style="width: 130px !important"
                    >
                      <input
                        type="text"
                        v-model="farmer.phoneNumber"
                        @blur="
                          validateInput(farmer.phoneNumber, 'phone', index)
                        "
                      />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <button @click="addNFarmers(10)">添加10行农户</button>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </template>
      </table>
      <p v-if="form.jyfs == '在线竞价交易'">
        *保证金由县运营公司统一管理，竞价结束后，未中标用户保证金将在5个工作日内退回原账号，中标用户保证金将转至出让人账户
      </p>
      <div
        slot="footer"
        class="dialog-footer"
        style="text-align: center; margin-top: -20px"
      >
        <el-button style="padding: 12px 40px" @click="gobackView"
          >取消</el-button
        >
        <el-button
          type="primary"
          :disabled="loading"
          style="padding: 12px 40px"
          @click="onSubmitOther"
          >发布</el-button
        >
        <!-- <el-button type="primary" style="padding: 12px 40px" @click="aa"
          >form</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { publicItemInfo, updateItemInfo } from "@/api/trustManagement";
import { uploadFile, deleteFile } from "@/api/fileApi";
import { getAreaTree } from "@/api/certificate";
import { getPropertyType } from "@/api/exchange";
import { mapGetters } from "vuex";
import { download } from "../../../../api/add";
import { removeEmptyChildren } from "@/js/utils/util";
import Vue from "vue";
export default {
  data() {
    return {
      baseUrl: window.globalUrl.HOME_API,
      type: "add",
      arr: [],
      // isUpload:true,
      fileList: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      opertion: [],
      areaType: [],
      checkList: [],
      proviceArr: [],
      types: [],
      prop: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
        expandTrigger: "hover",
      },
      errorStatus: [],
      form: {
        bz: "",
        cjdj: null,
        cjzj: null,
        cqlxbh: "",
        cqzsList: [],
        // crf: '',
        // crfId: 0,
        currentIdentity: "",
        // dqbj: 0,
        dz: "",
        fbsj: "",
        fkfs: "",
        ggjzsj: "",
        ggkssj: "",
        ghtjList: [],
        pgjzList: [],
        gpdj: null,
        gpjg: null,
        jjfd: 100,
        jjjzsj: null,
        jpys: 5,
        jybzj: null,
        jyfs: "",
        llcs: null,
        lxfs: "",
        lxr: "",
        lzlx: null,
        lznx: null,
        mjdw: "0",
        nz: "",
        qtsm: "",
        sfjnbzj: "",
        sfsyzlz: "",
        srf: "",
        srfId: 0,
        unit: null,
        xctpList: [],
        xmbh: "",
        xmbsm: "",
        xmdz: "",
        xmmc: "",
        xmzt: "",
        xz: "",
        xzqbh: "",
        zcmj: null,
        zlzzmList: [],
        farmersList: [], // 农户名单
        lzhdyt: "", // 流转用途
        syzq: "", // 收益周期
        tzfs: "", // 调整方式
        zffs: 0, // 支付方式(0 请选择 1-次性支付2分期支付)
        txrq: "", //支付日期填写
        zfsj: "", // 分期支付的时间(0 当年 1 后一年)
        gffhbz: "",
        fhgjlx: "",
        fhgjxq: "",
        fhrq: "",
        fhsj: "",
        bdsyqs: "",
        bdsyns: "",
        bdsytzfs: "",
        bdsyrq: "",
        bdsysj: "",
        proportionInvestment: null,
      },

      // 用来跟踪用户是否已经进行了选择
      userHasSelected: false,
      checked1: false, // 默认不选中
      userType: "",
      cropType: [],
      formRules: {
        serviceType: [
          { required: true, message: "请选择接管类型", trigger: "change" },
        ],
        serviceScope: [
          { required: true, message: "请选择作物类型", trigger: "change" },
        ],
        operScope: [
          { required: true, message: "请选择农事环节", trigger: "change" },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          const oneday = date.getTime();
          return time.getTime() < new Date().getTime() - 86400000;
        },
      },

      loading: false,
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  props: ["editData", "visible"],
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {
    "form.gffhbz": function () {
      if(this.form.gffhbz !=0){
        this.form.fhgjlx="0"
      }

    },
    "form.cqlxbh": function () {
      if (this.form.cqlxbh == "01" && this.form.currentIdentity == "3") {
        // 村集体选择集体建设用地时，默认选择出让流转方式，如果不是则默认
        if (this.form.lzlx == null) {
          this.form.lzlx = 3;
        } else {
          this.form.lzlx = this.form.lzlx;
        }
        this.form.mjdw = "0";
        this.form.jyfs = "在线竞价交易";
      } else {
        this.form.jyfs = "在线磋商";
      }

      if (this.form.cqlxbh != "01" && this.form.currentIdentity == "3") {
        if (this.form.lzlx == 3) {
          this.form.lzlx = null;
        }
      }
    },
    "form.jyfs": function () {
      if (this.form.jyfs == "在线竞价交易") {
        (this.form.farmersList = []), // 农户名单
          (this.form.lzhdyt = ""), // 流转用途
          (this.form.syzq = ""), // 收益周期
          (this.form.tzfs = ""), // 调整方式
          (this.form.zffs = 0), // 支付方式(0 请选择 1-次性支付2分期支付)
          (this.form.txrq = ""), //支付日期填写
          (this.form.zfsj = ""), // 分期支付的时间(0 当年 1 后一年)
          (this.form.gffhbz = "");
        this.form.fhgjlx = "";
        this.form.fhgjxq = "";
        this.form.fhrq = "";
        this.form.fhsj = "";
        this.form.bdsyqs = "";
        this.form.bdsyns = "";
        this.form.bdsytzfs = "";
        this.form.bdsyrq = "";
        this.form.bdsysj = "";
      } else if (this.form.jyfs == "在线磋商") {
        (this.form.jjfd = 0),
          (this.form.jjjzsj = null),
          (this.form.jpys = 5),
          (this.form.jybzj = null);
      }
    },
    "form.lzlx": function () {
      if (this.form.lzlx == 1) {
        (this.form.lzhdyt = ""), // 流转用途
          (this.form.syzq = ""), // 收益周期
          (this.form.tzfs = ""), // 调整方式
          (this.form.zffs = 0), // 支付方式(0 请选择 1-次性支付2分期支付)
          (this.form.txrq = ""), //支付日期填写
          (this.form.zfsj = ""); // 分期支付的时间(0 当年 1 后一年)
      } else if (this.form.lzlx == 0) {
        this.form.gffhbz = "";
        this.form.fhgjlx = "";
        this.form.fhgjxq = "";
        this.form.fhrq = "";
        this.form.fhsj = "";
        this.form.bdsyqs = "";
        this.form.bdsyns = "";
        this.form.bdsytzfs = "";
        this.form.bdsyrq = "";
        this.form.bdsysj = "";
      } else if (this.form.lzlx == 2) {
        (this.form.farmersList = []), // 农户名单
          (this.form.lzhdyt = ""), // 流转用途
          (this.form.syzq = ""), // 收益周期
          (this.form.tzfs = ""), // 调整方式
          (this.form.zffs = 0), // 支付方式(0 请选择 1-次性支付2分期支付)
          (this.form.txrq = ""), //支付日期填写
          (this.form.zfsj = ""), // 分期支付的时间(0 当年 1 后一年)
          (this.form.gffhbz = "");
        this.form.fhgjlx = "";
        this.form.fhgjxq = "";
        this.form.fhrq = "";
        this.form.fhsj = "";
        this.form.bdsyqs = "";
        this.form.bdsyns = "";
        this.form.bdsytzfs = "";
        this.form.bdsyrq = "";
        this.form.bdsysj = "";
        (this.form.jjfd = 0),
          (this.form.jjjzsj = ""),
          (this.form.jpys = 0),
          (this.form.jybzj = null);
      }
    },

    "form.farmersList": function () {
      this.checked1 = this.form.farmersList.length >= 1 ? true : false;
      if (this.form.farmersList.length - 1) {
        for (let i = 0; i < this.form.farmersList.length - 1; i++) {
          this.errorStatus.push({
            nameError: false,
            areaError: false,
            identityError: false,
            phoneError: false,
          });
        }
      }
    },
    "form.zffs": function () {
      this.form.txrq = "";
      if (this.form.zffs == 0) {
        this.form.txrq = "";
        this.form.zfsj = "";
      }
      if (this.form.zffs == 1) {
        this.form.zfsj = "";
      }
      console.log(this.form.txrq);
      console.log(this.form.zfsj);
    },
    checked1: function () {
      if (this.checked1 == false) {
        this.form.farmersList = [];
        console.log(1);
      } else {
        for (let i = 0; i < 10; i++) {
          this.form.farmersList.push({
            id: 0,
            farmerName: "",
            area: "",
            identityId: "",
            phoneNumber: "",
            xmbh: "",
            createTime: "",
            updateTime: "",
            createId: "",
            updateId: "",
            deleted: "",
          });
          // 添加对应的错误状态到 errorStatus 数组
          this.errorStatus.push({
            nameError: false,
            areaError: false,
            identityError: false,
            phoneError: false,
          });
        }
      }
    },
    "form.sfsyzlz": function () {
      if (
        this.identityObj.identityTypeCode == "4" &&
        this.form.sfsyzlz == "0"
      ) {
        // this.$message.error('非再次流转时需要上传本主体产权证书');
        this.$message({
          showClose: true,
          message: "非再次流转时需要上传本主体产权证书",
        });
      }
    },
    visible: function () {
      if (this.visible) {
        this.loading = false;
        this.form = this.editData;
        this.form.bmrs = null;
        if (this.type == "reAdd") {
          this.form.jjjzsj = null;
        }
        this.setUrl();
        if (this.identityObj) {
          this.form.lxr = this.identityObj.contactName;
          this.form.lxfs = this.identityObj.contactPhone;
          this.form.currentIdentity = this.identityObj.identityTypeCode;
          this.userType = this.identityObj.identityTypeCode;
          this.form.xzqbh = this.identityObj.areaCode;
          this.form.xmdz = this.identityObj.areaFullName;
        }
      }
    },
    fileList: {
      handler(newVal, oldVal) {
        // 检查每个文件对象的 name 属性
        // this.$nextTick(() => {
        // 在 DOM 更新之后执行回调函数
        newVal.forEach((file) => {
          if (file.name.toLowerCase().endsWith("pdf")) {
            // 修改符合条件的文件对象的 url 属性
            file.url =
              "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
          }
          // });
          // console.log("fileList", this.fileList);
          // console.log("文件列表已更新");
          // console.log("新值:", newVal);
        });
        // 在这里可以根据实际需求执行相应操作
      },
      deep: true, // 深度监听数组内部元素的变化
    },
  },
  created() {
    this.getRegions();
    this.initPropertyType();
  },

  mounted() {
    if (this.identityObj.identityTypeCode == 4) {
      this.form.sfsyzlz = "1";
      console.log(this.form.sfsyzlz);
    }
  },
  methods: {
    aa() {
      console.log(JSON.parse(JSON.stringify(this.form)));
    },

    validateInput(value, type, index) {
      if (!value) {
        // 输入内容为空，不进行校验
        // 输入内容为空，将当前框的错误状态设置为 false
        switch (type) {
          case "name":
            this.errorStatus[index].nameError = false;
            break;
          case "area":
            this.errorStatus[index].areaError = false;
            break;
          case "id":
            this.errorStatus[index].identityError = false;
            break;
          case "phone":
            this.errorStatus[index].phoneError = false;
            break;
          default:
          // do nothing
        }
        return;
      }
      let regex;
      switch (type) {
        case "name":
          regex = /^[\u4e00-\u9fa5]+$/;
          break;
        case "area":
          regex = /^\d+(\.\d+)?$/;
          break;
        case "id":
          regex = /^\d{17}(\d|X|x)$/;
          break;
        case "phone":
          regex = /^1[3456789]\d{9}$/;
          break;
        default:
          regex = /.*/; // 默认不校验
      }

      if (!regex.test(value)) {
        switch (type) {
          case "name":
            this.errorStatus[index].nameError = true;
            break;
          case "area":
            this.errorStatus[index].areaError = true;
            break;
          case "id":
            this.errorStatus[index].identityError = true;
            break;
          case "phone":
            this.errorStatus[index].phoneError = true;
            break;
          default:
          // do nothing
        }
      } else {
        switch (type) {
          case "name":
            this.errorStatus[index].nameError = false;
            break;
          case "area":
            this.errorStatus[index].areaError = false;
            break;
          case "id":
            this.errorStatus[index].identityError = false;
            break;
          case "phone":
            this.errorStatus[index].phoneError = false;
            break;
          default:
          // do nothing
        }
      }
    },
    // 添加行
    addNFarmers(n) {
      for (let i = 0; i < n; i++) {
        this.form.farmersList.push({
          id: 0,
          farmerName: "",
          area: "",
          identityId: "",
          phoneNumber: "",
          xmbh: "",
          createTime: "",
          updateTime: "",
          createId: "",
          updateId: "",
          deleted: "",
        });
        // 添加对应的错误状态到 errorStatus 数组
        this.errorStatus.push({
          nameError: false,
          areaError: false,
          identityError: false,
          phoneError: false,
        });
      }
      console.log(this.form.farmersList);
    },
    uploadFileSuccess(response, file, fileList) {
      console.log("上传成功", response, file, fileList);
    },
    progress(event, file, fileList) {
      this.$nextTick(() => {
        console.log("9999", event, file, fileList);
      });
    },

    async initPropertyType() {
      // 初始化产权类型
      let res = await getPropertyType();
      console.log("初始化产权类型", res);
      let { status, data } = res;
      if (status) {
        data.forEach((element) => {
          this.types.push({
            bh: element.bh,
            lx: element.lx,
          });
        });
      }
    },
    // 获取项目地址
    handleChange() {
      let dz = this.$refs["xmdz"].getCheckedNodes()[0].pathLabels.join("/");
      console.log("地址",dz)
      this.form.xmdz = dz;
      // console.log(11111111111);
    },
    // 监听总价变化计算单价
    zjChange() {
      if (this.form.zcmj && this.form.lznx && this.form.gpjg) {
        this.form.gpdj = (
          this.form.gpjg /
          this.form.zcmj /
          this.form.lznx
        ).toFixed(2);
      }
    },
    // 监听单价变化计算总价
    djChange() {
      if (this.form.zcmj && this.form.lznx && this.form.gpdj) {
        this.form.gpjg = (
          this.form.gpdj *
          this.form.zcmj *
          this.form.lznx
        ).toFixed(0);
      }
    },
    // beforeUpload(file) {
    //   console.log("beforeUpload:", file);
    //   // 匹配正则
    //   const isJPG = file.raw.type === "application/pdf";
    //   // const isJPG = /image\/(jpg|jpeg|gif|png)/.test(file.raw.type);
    //   console.log(isJPG);
    //   const isLt2M = file.raw.size / 1024 / 1024 < 6;
    //   if (!isJPG) {
    //     this.$message.error("上传pdf文件或JPG/GIF/PNG格式!");
    //     this.isUpload = false;
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传图片大小不能超过 6MB!");
    //     this.isUpload = false;
    //   }
    //   return isJPG && isLt2M;
    // },
    beforeUpload(file) {
      console.log("beforeUpload:", file);
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        this.fileList.splice(this.fileList.length, 1);
        // this.isUpload = false;
      }
      // return isLt2M;
    },
    // 删除已上传的产权证明
    removeCqzs(file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
      this.form.cqzsList = this.form.cqzsList.filter((x) => {
        x = JSON.parse(x);
        if (x.name != file.name) {
          return JSON.stringify(x);
        }
      });
    },
    // 删除已上传的现场图片
    removeXctp(file, fileList) {
      this.fileList2 = fileList;
      this.form.xctpList = this.form.xctpList.filter((x) => {
        x = JSON.parse(x);
        if (x.name != file.name) {
          return JSON.stringify(x);
        }
      });
    },
    // 删除已上传的再流转证明
    removeZlzzm(file, fileList) {
      this.fileList3 = fileList;
      this.form.zlzzmList = this.form.zlzzmList.filter((x) => {
        x = JSON.parse(x);
        if (x.name != file.name) {
          return JSON.stringify(x);
        }
      });
    },
    // 删除已上传的审批文件
    removeSpjg(file, fileList) {
      this.fileList4 = fileList;
      this.form.ghtjList = this.form.ghtjList.filter((x) => {
        x = JSON.parse(x);
        if (x.name != file.name) {
          return JSON.stringify(x);
        }
      });
    },
    // 删除已上传的评估结果
    removePgjg(file, fileList) {
      this.fileList5 = fileList;
      this.form.pgjzList = this.form.pgjzList.filter((x) => {
        x = JSON.parse(x);
        if (x.name != file.name) {
          return JSON.stringify(x);
        }
      });
    },
    // 上传产权证明           在这里更改数据.pgf 图片
    uploadCqzs(file, fileList) {
      const isLt6M = file.size / 1024 / 1024 < 6;
      console.log("file.size", file.size);
      // 检查文件大小是否大于6MB，大于则删除
      if (!isLt6M) {
        const index = this.fileList.indexOf(file);
        if (index !== -1) {
          this.fileList.splice(index, 1);
        }
        this.$message.error("上传图片大小不能超过 6MB!");
        return isLt6M;
      }

      this.fileList = fileList;
      this.fileList.forEach((file) => {
        if (file.name.toLowerCase().endsWith("pdf")) {
          // 修改符合条件的文件对象的 url 属性
          file.url =
            "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
        }

        // this.beforeUpload(file);

        // const isLt2M = file.size / 1024 / 1024 < 6;
        // if (!isLt2M) {
        //   this.$message.error("上传图片大小不能超过 6MB!");
        // this.isUpload = false;
        // }
        // console.log("fileList", this.fileList);
        // console.log("文件列表已更新");
        // console.log("新值:", newVal);
      });
      // console.log("cheng",file,fileList);
      this.uploadFile(file, fileList, "cqzs");
      console.log("fileList", this.fileList);
    },
    // 上传现场图片
    uploadXctp(file, fileList) {
      this.uploadFile(file, fileList, "xctp");
    },
    // 上传再流转证明
    uploadZlzzm(file, fileList) {
      // console.log(file,fileList,'打印1和2')
      this.uploadFile(file, fileList, "zlzzm");
    },
    // 上传审批结果
    uploadSpjg(file, fileList) {
      this.uploadFile(file, fileList, "spjg");
    },
    // 上传评估结果
    uploadPgjg(file, fileList) {
      this.uploadFile(file, fileList, "pgjg");
    },
    // 统一文件上传方法
    async uploadFile(file, fileList, type) {
      let param = new FormData();
      param.append("catalog", "vip");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      if (res.code == 0) {
        if (type == "cqzs") {
          if (!this.form.cqzsList) {
            this.form.cqzsList = [];
          }
          let fileData = JSON.stringify({
            name: res.data.sourceFileName,
            url: res.data.url,
          });
          this.form.cqzsList.push(fileData);
          // this.getViewUrl(null, 'cqzs', fileData)
        } else if (type == "xctp") {
          if (!this.form.xctpList) {
            this.form.xctpList = [];
          }
          let fileData = JSON.stringify({
            name: res.data.sourceFileName,
            url: res.data.url,
          });
          this.form.xctpList.push(fileData);
          // this.getViewUrl(null, 'xctp', fileData)
        } else if (type == "zlzzm") {
          if (!this.form.zlzzmList) {
            this.form.zlzzmList = [];
          }
          let fileData = JSON.stringify({
            name: res.data.sourceFileName,
            url: res.data.url,
          });
          this.form.zlzzmList.push(fileData);
          // this.getViewUrl(null, 'zlzzm', fileData)
        } else if (type == "spjg") {
          if (!this.form.ghtjList) {
            this.form.ghtjList = [];
          }
          let fileData = JSON.stringify({
            name: res.data.sourceFileName,
            url: res.data.url,
          });
          this.form.ghtjList.push(fileData);
          // this.getViewUrl(null, 'ghtj', fileData)
        } else if (type == "pgjg") {
          if (!this.form.pgjzList) {
            this.form.pgjzList = [];
          }
          let fileData = JSON.stringify({
            name: res.data.sourceFileName,
            url: res.data.url,
          });
          this.form.pgjzList.push(fileData);
          // this.getViewUrl(null, 'pgjz', fileData)
        }
        // this.$message.success(res.mssage)
      } else {
        this.$message.error(res.msg ? res.msg : "上传失败");
      }
    },
    // 处理文件json字符串
    setUrl() {
      if (this.form.cqzsList && this.form.cqzsList.length > 0) {
        this.getViewUrl(this.form.cqzsList, "cqzs", null);
      }
      if (this.form.xctpList && this.form.xctpList.length > 0) {
        this.getViewUrl(this.form.xctpList, "xctp", null);
      }
      if (this.form.zlzzmList && this.form.zlzzmList.length > 0) {
        this.getViewUrl(this.form.zlzzmList, "zlzzm", null);
      }
      if (this.form.ghtjList && this.form.ghtjList.length > 0) {
        this.getViewUrl(this.form.ghtjList, "ghtj", null);
      }
      if (this.form.pgjzList && this.form.pgjzList.length > 0) {
        this.getViewUrl(this.form.pgjzList, "pgjz", null);
      }
    },
    // 文件回显
    getViewUrl(arr, type, data) {
      if (arr) {
        let list = [];
        list = arr.map((item) => {
          let res = JSON.parse(item);
          return {
            name: res.name,
            url: this.baseUrl + "admin/file/get?ossFilePath=" + res.url,
            downloadUrl: res.url,
          };
        });
        switch (type) {
          case "cqzs":
            this.fileList = list;
            break;
          case "xctp":
            this.fileList2 = list;
            break;
          case "zlzzm":
            this.fileList3 = list;
            break;
          case "ghtj":
            this.fileList4 = list;
            break;
          case "pgjz":
            this.fileList5 = list;
            break;
        }
      }
      if (data) {
        let res = JSON.parse(data);
        let fileData = {
          name: res.name,
          url: this.baseUrl + "admin/file/get?ossFilePath=" + res.url,
          downloadUrl: res.url,
        };
        switch (type) {
          case "cqzs":
            this.fileList.push(fileData);
            break;
          case "xctp":
            this.fileList2.push(fileData);
            break;
          case "zlzzm":
            this.fileList3.push(fileData);
            break;
          case "ghtj":
            this.fileList4.push(fileData);
            break;
          case "pgjz":
            this.fileList5.push(fileData);
            break;
        }
      }
    },
    // 点击下载已上传文件
    downloadZlzzm(file) {
      this.form.zlzzmList.map((x) => {
        let data = JSON.parse(x);
        if (file.name == data.name) {
          this.download(data);
        }
      });
    },
    downloadXctp(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      // this.form.xctpList.map((x) => {
      //   let data = JSON.parse(x);
      //   if (file.name == data.name) {
      //     this.download(data);
      //   }
      // });
    },
    downloadCqzs(file) {
      // console.log("wxf",file);
      this.form.cqzsList.map((x) => {
        let data = JSON.parse(x);
        if (file.name == data.name) {
          this.download(data);
        }
      });
    },
    downloadSpjg(file) {
      this.form.ghtjList.map((x) => {
        let data = JSON.parse(x);
        if (file.name == data.name) {
          this.download(data);
        }
      });
    },
    downloadPgjg(file) {
      this.form.pgjzList.map((x) => {
        let data = JSON.parse(x);
        if (file.name == data.name) {
          this.download(data);
        }
      });
    },
    // 提交
    async onSubmitOther() {
      // 判断农户列表有错吗？
      // 遍历检查每个错误状态
      for (let error of this.errorStatus) {
        console.log("error", error);
        if (
          error.nameError ||
          error.areaError ||
          error.identityError ||
          error.phoneError
        ) {
          // 如果任何一个错误状态为 true，则显示相应的错误信息或采取其他措施
          this.$message.error("农户列表有错");
          return; // 退出函数，防止继续提交
        }
      }

      this.loading = true;
      if (this.userType == "3" && this.form.cqlxbh == "01") {
        if (this.form.ghtjList.length == 0 || this.form.pgjzList.length == 0) {
          this.$message.warning(
            "请上传地块规划设计条件审批结果和地块评估价值结果"
          );
          this.loading = false;
          return;
        }
      }
      if (this.form.mjdw=='') {
        this.$message.warning("请选择面积单位");
        this.loading = false;
        return;
      }
      // if (this.form.zffs != 0) {
      //   if (this.form.txrq != "") {
      //     this.$message.warning("请填写完整租金支付方式");
      //     this.loading = false;
      //     return;
      //   }
      // }
      if (this.form.syzq != "" && !this.form.tzfs) {
        this.$message.warning("请填写完整具体调整方式");
        this.loading = false;
        return;
      }
      let params = Object.assign(this.form);
      console.log(params, "这是params");
      // let res = null
      if (this.type == "add" || this.type == "reAdd") {
        publicItemInfo(params).then((res) => {
          let { status, message } = res;
          if (status == 200) {
            this.$message.success(message);
            this.handleClose();
          } else {
            this.$message.error(message);
            this.loading = false;
          }
        });
      } else if (this.type == "edit") {
        updateItemInfo(params).then((res) => {
          let { status, message } = res;
          if (status == 200) {
            this.$message.success(message);
            this.handleClose();
          } else {
            this.$message.error(message);
            this.loading = false;
          }
        });
      }
    },
    // 关闭
    handleClose(done) {
      this.fileList = [];
      this.fileList2 = [];
      this.fileList3 = [];
      this.fileList4 = [];
      this.fileList5 = [];
      this.$emit("closeDialog", false);
    },
    gobackView() {
      this.fileList = [];
      this.fileList2 = [];
      this.fileList3 = [];
      this.fileList4 = [];
      this.fileList5 = [];
      this.$emit("closeDialog", false);
    },
    // 获取行政区树
    async getRegions() {
      let param = {
        level: "5",
      };
      let res = await getAreaTree(param);
      // console.log("行政区",res);
      let { code, data, msg } = res;
      if (code == 0) {
        this.proviceArr = removeEmptyChildren(data);
      } else {
        this.$message.error(msg);
      }
    },
    // 执行下载
    async download(file) {
      let res = await download({ ossFilePath: file.url });
      if (res) {
        let blob = res;
        const fileName = file.name;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>
<style lang="less" scoped>
input {
  border: none; /* 移除边框 */
  font-size: 16px; /* 设置字体大小为默认大小 */
  // padding: 4px; /* 设置内边距 */
  outline: none; /* 移除选中时的边框 */
}
/deep/.el-upload-list__item {
  width: 30%;
}

/deep/.el-upload-list {
  display: flex;
  flex-wrap: wrap;
}

.farmers-table {
  width: 100%;
  border-collapse: collapse;
}

.farmers-table th,
.farmers-table td {
  // text-align: center;
  width: auto !important;
  padding: 8px;
  border: 1px solid #ddd;
  input {
    text-align: center;
    width: 100%;
    border: none; /* 移除边框 */
    font-size: 16px; /* 设置字体大小为默认大小 */
    // padding: 4px; /* 设置内边距 */
    outline: none; /* 移除选中时的边框 */
  }
}

.farmers-table th {
  background-color: #f2f2f2;
}

table {
  /deep/.el-input {
    border: 1px solid gray;
    width: 80%;
  }

  /deep/.el-select {
    width: 80%;

    .el-input {
      width: 100%;
    }

    .el-input.is-focus .el-input__inner {
      border-color: transparent;
    }
  }

  /deep/.el-input-group__append {
    padding: 0 12px;
  }

  /deep/.el-input__inner:focus {
    border: none !important;
  }

  /deep/.el-input__inner:hover {
    border-color: transparent !important;
  }

  /deep/.el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  border-collapse: collapse;

  tr {
    color: #333;

    td {
      width: 160px;
      text-align: center;
      height: 12px;
      padding: 6px;

      span {
        color: red;
      }
    }

    td:nth-child(2n) {
      width: 380px !important;
    }

    .dis {
      background: #d2d4d7;
      cursor: disabled;
    }
  }
}

/deep/.el-dialog__body {
  padding-left: 130px;
}

.upload-demo .pdf i::before {
  content: "\f1c1"; /* Font Awesome 中的 PDF 图标编码 */
}
.upload-demo .file i::before {
  content: "\f15b"; /* Font Awesome 中的文件图标编码 */
}

.error-cell {
  // background-color: lightcoral;
  border-bottom: 3px solid red !important;
}
</style>
